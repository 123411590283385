import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru'

export const sortWiringsByDate = (wirings) => {
    const sorted = { };
    for(let i = 0; i < wirings.length; i++) {
        const wiring = wirings[i];
        const { data } = wiring;
        const { paymentDate } = data;

        const formatDate = dayjs(paymentDate).locale(ru).format('MMM YY')
        if(sorted[formatDate]) {
            sorted[formatDate].push(wiring)
        } else {
            sorted[formatDate] = [wiring]
        }
    }
    return sorted
}

export const sortWiringsByType = (sortedWirings) => {
    const sorted = Object.entries(sortedWirings).reduce((acc, [dateKey, wirings]) => {
        acc[dateKey] = wirings.reduce((object, {data}) => {
            if(data.type === 'fact') {
                object.fact.push(data)
            } else {
                object.plan.push(data)
            }
            return object
        }, {
            plan: [],
            fact: []
        })
        return { ...acc }
    }, {});
    return sorted
}

export const sortWiringsByArticleAction = (sortedWiringsByType) => {
    const sorted = {};
    for (let key in sortedWiringsByType) {
        sorted[key] = {};
        sorted[key]['fact'] = sortedWiringsByType[key].fact.reduce((acc, data) => {
            if(data.article.action === 'plus') {
                acc.plus += Number(data.sum)
            } else {
                acc.minus += Number(data.sum)
            }
            return acc
        }, {
            plus: 0,
            minus: 0
        })
        sorted[key]['plan'] = sortedWiringsByType[key].plan.reduce((acc, data) => {
            if(data.article.action === 'plus') {
                acc.plus += Number(data.sum)
            } else {
                acc.minus += Number(data.sum)
            }
            return acc
        }, {
            plus: 0,
            minus: 0
        })
    }
    return sorted
}