<template>
    <div>
        <div class="btn-container">
            <v-checkbox :ripple="false" v-model="selectedTypes" :value="wiringType.fact" :label="wiringType.fact"></v-checkbox>
            <v-checkbox :ripple="false" v-model="selectedTypes"  :value="wiringType.plan" :label="wiringType.plan"></v-checkbox>
        </div>
        <div class="graph-container">
                <Bar
                    :chart-options="chartOptions"
                    :chart-data="chartData"
                />
                <Bar
                    :chart-options="totalChartOptions"
                    :chart-data="chartDataTotal"
                />
        </div>
        <div class="mt-8 d-flex align-center" v-if="filterChips.length">Проводки отфильтрованы по:
            <div class="chip-container ml-5 d-flex flex-wrap">
                <v-chip 
                    v-for="(item, index) in filterChips" 
                    :key="index"
                    close
                    @click:close="removeFilterFromGraph({date: item.date, tag: item.tag})"
                    outlined
                    label
                >
                    {{ item.text }}
                </v-chip>
            </div>
        </div>
        <v-btn v-if="filterChips.length" class="mt-5" outlined @click="clearFilter">Сбросить фильтры</v-btn>
    </div>
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { wiringType } from '../../../../register/WireDict'
import { sortWiringsByDate, sortWiringsByType, sortWiringsByArticleAction } from './model'
import dayjs from 'dayjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const TYPE_TAGS = {
    'plan-plus': 'Доход - план',
    'fact-plus': 'Доход - факт',
    'plan-minus': 'Расход - план',
    'fact-minus': 'Расход - факт',
}

export default {
    name: 'FinanceGraph',
    props: ['inputWirings', 'filterByGraph'],
    components: { Bar },
    data() {
        return {
            selectedTypes: [wiringType.fact, wiringType.plan],
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                onClick: (e, indexes) => {
                    indexes[0] && this.setFilterByGraph(indexes[0])
                }
            },
            totalChartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                onClick: (e, indexes) => {
                    indexes[0] && this.setFilterByTotalGraph(indexes[0])
                }
            },
            wiringType,
            datasets: {},
            totalDatasets: {}
        }
    },
    methods: {
        clearFilter() {
            this.$emit('updateFilterByGraph', {})
        },
        setFilterByTotalGraph(indexes) {
            const { datasetIndex } = indexes;
            const { tag, wiringType, action } = this.graphDataSet[datasetIndex]

            if(this.filterByGraph['итого'] && this.filterByGraph['итого'].map((item) => item.tag).includes(tag)) return;
            const filters = {...this.filterByGraph}
            if(filters['итого']) {
                filters['итого'].push({tag, wiringType, action});
            } else {
                filters['итого'] = [{tag, wiringType, action}]
            }

            this.$emit('updateFilterByGraph', filters)            
        },
        setFilterByGraph(indexes) {
            const { index: monthIndex, datasetIndex } = indexes;
            const date = this.labelMonths[monthIndex];
            const { tag, wiringType, action } = this.graphDataSet[datasetIndex]

            if(this.filterByGraph[date] && this.filterByGraph[date].map((item) => item.tag).includes(tag)) return;
            const filters = {...this.filterByGraph}
            if(filters[date]) {
                filters[date].push({tag, wiringType, action});
            } else {
                filters[date] = [{tag, wiringType, action}]
            }

            this.$emit('updateFilterByGraph', filters)            
        },
        removeFilterFromGraph({date, tag: delTag}) {
            const filters = {...this.filterByGraph}
            filters[date] = this.filterByGraph[date].filter(({tag}) => tag !== delTag)
            if(filters[date].length === 0) {
                delete filters[date]
            }
            this.$emit('updateFilterByGraph', filters)
        }
    },
    computed: {
        filterChips() {
            const chips = Object.entries(this.filterByGraph).reduce((acc, [date, array]) => {
                return [...acc, ...array.map(({tag}) => ({
                    text: `${TYPE_TAGS[tag]} ${date}`,
                    date: date,
                    tag: tag
                }))]
            }, [])
            return chips;
        },
        labelMonths() {
            const months = this.sortedWirignsData.map(([ date ]) => date)
            return months;
        },
        sortedWirignsData() {
            if(!this.inputWirings) return null;            
            const sortedWiringsByDate = sortWiringsByDate(this.inputWirings);
            const sortedWiringsByType = sortWiringsByType(sortedWiringsByDate);
            const sortedWirignsByArticleAction = sortWiringsByArticleAction(sortedWiringsByType)
            const sorted = Object.entries(sortedWirignsByArticleAction).sort(([date1], [date2]) => {
                const first = dayjs(date1, 'MMM YY', 'ru')
                const second = dayjs(date2, 'MMM YY', 'ru')
                return first.isBefore(second) ? -1 : 1
            })
            return sorted;
        },
        totalProjectSum() {
            const totalSum = this.sortedWirignsData.reduce((total, [,data]) => {
                const { plan, fact } = data;
                total.plan.plus += plan.plus
                total.plan.minus += plan.minus
                total.fact.plus += fact.plus
                total.fact.minus += fact.minus
                return total
            }, {
                plan: {
                    plus: 0,
                    minus: 0
                },
                fact: {
                    plus: 0,
                    minus: 0
                }
            })
            return totalSum
        },
        chartDataTotal() {
            return ({
                labels: ['Итого'],
                datasets: this.selectedTypes.reduce((acc, item) => [...acc, ...this.totalDatasets[item]], []).sort((a, b) => a.sortOrder - b.sortOrder)
            })
        },
        graphDataSet() {
            return this.selectedTypes.reduce((acc, item) => [...acc, ...this.datasets[item]], []).sort((a, b) => a.sortOrder - b.sortOrder);
        },
        chartData() {
            return ({
                labels: this.labelMonths,
                datasets: this.graphDataSet
            })
        }
    },
    beforeMount() {
        this.datasets = {
            [wiringType.fact]: [{
                label: 'Доход - факт',
                tag: 'fact-plus',
                wiringType: 'fact',
                sortOrder: 1,
                action: 'plus',
                data: this.sortedWirignsData.map(([, sum ]) => sum.fact.plus),
                borderColor: 'dodgerblue',
                backgroundColor: 'dodgerblue',
                enable: false,
            },
            {
                label: 'Расход - факт',
                tag: 'fact-minus',
                wiringType: 'fact',
                sortOrder: 3,
                action: 'minus',
                data: this.sortedWirignsData.map(([, sum ]) => sum.fact.minus),
                borderColor: 'darkred',
                backgroundColor: 'darkred',
            }],
            [wiringType.plan]: [{
                label: 'Доход - план',
                tag: 'plan-plus',
                wiringType: 'plan',
                action: 'plus',
                sortOrder: 2,
                data: this.sortedWirignsData.map(([, sum ]) => sum.plan.plus),
                borderColor: 'skyblue',
                backgroundColor: 'skyblue',
            },
            {
                label: 'Расход - план',
                tag: 'plan-minus',
                wiringType: 'plan',
                action: 'minus',
                sortOrder: 4,
                data: this.sortedWirignsData.map(([, sum ]) => sum.plan.minus),
                borderColor: 'indianred',
                backgroundColor: 'indianred',
            }]
        }
        this.totalDatasets = {
            [wiringType.fact]: [{
                label: 'Доход - факт',
                data: [this.totalProjectSum.fact.plus],
                sortOrder: 1,
                borderColor: 'dodgerblue',
                backgroundColor: 'dodgerblue',
                enable: false,
            },
            {
                label: 'Расход - факт',
                data: [this.totalProjectSum.fact.minus],
                borderColor: 'darkred',
                sortOrder: 3,
                backgroundColor: 'darkred',
            }],
            [wiringType.plan]: [{
                label: 'Доход - план',
                data: [this.totalProjectSum.plan.plus],
                borderColor: 'skyblue',
                sortOrder: 2,
                backgroundColor: 'skyblue',
            },
            {
                label: 'Расход - план',
                data: [this.totalProjectSum.plan.minus],
                borderColor: 'indianred',
                sortOrder: 4,
                backgroundColor: 'indianred',
            }]
        }
    }
}

</script>

<style scoped>
    .btn-container {
        display: flex;
        gap: 60px;
    }
    .graph-container {
        display: grid;
        grid-template-rows: 1;
        grid-template-columns: 65% 30%;
        gap: 5%;
    }
    .chip-container {
        gap: 10px;
    }
</style>