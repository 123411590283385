const reduceSumFromAllWirings = (wirings, field) => {
    const { plan, fact } = wirings.reduce((acc, entry) => {
        const { data: { type, sum } } = entry;
        return {...acc, [type]: acc[type] + Number(sum)}
    }, {
        plan: field.plan,
        fact: field.fact
    })
    return {
        plan,
        fact
    }
}

const addItemToArray = (array, item) => {
    array.plan.push(item.plan)
    array.fact.push(item.fact)
}


export class CALCULATE_PASSPORT_CLASS {
    constructor( ) {
        this.contractSumCount = {
            plan: 0,
            fact: 0
        }
        this.viTotalSumSubtrahend = {
            plan: [],
            fact: []
        }
        this.viContractSumWithoutNds = {
            plan: 0,
            fact: 0
        }
        this.hiTotalTaxSum = {
            plan: [],
            fact: []
        }
        this.hiContractTotalSum = {
            plan: 0,
            fact: 0
        }
        this.totalOthersSum = {
            plan: [],
            fact: []
        }
    }
    contractSum(wirings, field) {
        const { plan, fact } = reduceSumFromAllWirings(wirings, field);
        this.contractSumCount.plan = plan;
        this.contractSumCount.fact = fact;
        return {
            plan,
            fact
        }
    }
    nds(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.viTotalSumSubtrahend, result)
        return result
    }
    cashlessPayment(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.viTotalSumSubtrahend, result)
        return result
    }
    irretrievablуGuarantee(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.viTotalSumSubtrahend, result)
        return result
    }
    cashlessReserve(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.viTotalSumSubtrahend, result)
        return result
    }
    viContractSum() {
        const decreaseFactSum = this.viTotalSumSubtrahend.fact.reduce((sum, item) => sum + item)
        const decreasePlanSum = this.viTotalSumSubtrahend.plan.reduce((sum, item) => sum + item)
        const result = {
            plan: this.contractSumCount.plan - decreasePlanSum,
            fact: this.contractSumCount.fact - decreaseFactSum
        }
        this.viContractSumWithoutNds = result;
        return result
    }
    taxSum(_, field) {
        const { value } = field;
        const result = this.getTaxSumFromContractSumWithoutNds(value)
        addItemToArray(this.hiTotalTaxSum, result)
        return result
    }
    cashingComissionSum(_, field) {
        const { value } = field;
        const result = this.getTaxSumFromContractSumWithoutNds(value)
        addItemToArray(this.hiTotalTaxSum, result)
        return result
    }
    overheadAmountSum(_, field) {
        const { value } = field;
        const result = this.getTaxSumFromContractSumWithoutNds(value)
        addItemToArray(this.hiTotalTaxSum, result)
        return result
    }
    payrollAmountSum(_, field) {
        const { value } = field;
        const result = this.getTaxSumFromContractSumWithoutNds(value)
        addItemToArray(this.hiTotalTaxSum, result)
        return result
    }
    hiContractSum() {
        const totalTaxFactSum = this.hiTotalTaxSum.fact.reduce((sum, item) => sum + item)
        const totalTaxPlanSum = this.hiTotalTaxSum.plan.reduce((sum, item) => sum + item)
        const result = {
            plan: this.viContractSumWithoutNds.plan - totalTaxPlanSum,
            fact: this.viContractSumWithoutNds.fact - totalTaxFactSum
        }
        this.hiContractTotalSum = result;
        return result
    }
    bonusScheme(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.totalOthersSum, result)
        return result
    }
    externalExpertsPayout(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.totalOthersSum, result)

        return result
    }
    otherProjectExpences(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.totalOthersSum, result)

        return result
    }
    reserve(wirings, field) {
        const result = reduceSumFromAllWirings(wirings, field);
        addItemToArray(this.totalOthersSum, result)
        return result
    }
    priProjectProfit() {
        const totalOthersFactSum = this.totalOthersSum.fact.reduce((sum, item) => sum + item)
        const totalOthersPlanSum = this.totalOthersSum.plan.reduce((sum, item) => sum + item)
        const result = {
            plan: this.hiContractTotalSum.plan - totalOthersPlanSum,
            fact: this.hiContractTotalSum.fact - totalOthersFactSum
        }
        return result
    }




    getTaxSumFromContractSumWithoutNds(value) {
        return {
            plan: this.viContractSumWithoutNds.plan * (value / 100),
            fact: this.viContractSumWithoutNds.fact * (value / 100)
        }
    }
}