<template>
    <v-data-table 
        v-model="selectedArticles"
        :items="fieldsWithSum"
        item-key="key"
        :headers="headers"
        :hide-default-footer="true"
        :disable-pagination="true"
        :disable-sort="true"
        show-select
        >
            <template v-slot:item="{ item: field, isSelected, select }">
                <tr>
                    <td>
                     <v-simple-checkbox 
                        v-if="field.filter" 
                        :value="isSelected" 
                        @input="select" 
                        :ripple="false" 
                    /> 
                </td>
                    <td class="d-flex align-center">
                        <div>
                            <span :class="{bold: field.heading}" >{{ field.text }}</span>{{ field.subtext }}
                        </div>
                    </td>
                    <td :class="{bold: field.heading}" v-if="field.plan !== undefined">{{ formatCurrency(field.plan) }}</td>
                    <td :class="{bold: field.heading}" v-if="field.fact !== undefined">{{ formatCurrency(field.fact) }}</td>
                    <template v-if="field.rateValue !== undefined">
                        <td >{{ field.rateValue }} %</td>
                        <td ></td>
                    </template>
                    <td class="text-center">{{ field.rateValue !== undefined ? "Изменить ставку" : '' }} </td>
                </tr>
            </template>
        </v-data-table>
</template>

<script>
import fields from './fields.json'
import { CALCULATE_PASSPORT_CLASS } from './calculatePassportFunctions'
import { headers } from './tableHeaders'
export default {
    name: 'PassportTable',
    props: ['inputWirings', 'filterArticles'],
    data() {
        return {
            headers,
            fields,
            selectedArticles: []
        }
    },
    computed: {
        fieldsWithSum() {
            const passport_calculator = new CALCULATE_PASSPORT_CLASS();
            const fieldsArray = Object.entries(this.fields);
            const fieldsWithSum = fieldsArray.map(([fieldKey, field]) => {
                const isCount = field.binding?.isCount;
                if(!isCount) return field;
                const funds = field.binding?.funds;
                const articles = field.binding?.articles;
                const filteredWirings = funds || articles 
                    ? this.inputWirings.filter(({ data }) => funds.includes(data.fund.fund) && articles.includes(data.article.article)) 
                    : this.inputWirings
                
                const { plan, fact } = passport_calculator[fieldKey](filteredWirings, field)
                const fieldWithSum = {...field, plan, fact };
                return fieldWithSum
            })
            return fieldsWithSum
        }
    },
    methods: {
        formatCurrency(value) {
            return new Intl.NumberFormat("ru", {style: "currency", currency: "RUB"}).format(value)
        }
    },
    watch: {
        selectedArticles(selected) {
            if(this.filterArticles.length === 0 && selected.length === 0) return;
            const filteredSelected = selected.filter((item) => item.filter)
            const articles = filteredSelected.reduce((acc, field) => {
                return [...acc, { key: field.key, articles: field.binding.articles }]
            }, []);
            this.$emit('updateFilterByArticles', articles);
        },
        filterArticles(articles) {
            if(articles.length === 0 && this.selectedArticles.length != 0) {
                this.selectedArticles = [];

            }
        }
    },
    created() {
        this.selectedArticles = this.fieldsWithSum
    }
}
</script>

<style scoped>
    .bold {
        font-weight: bold;
    }
    .checkbox_container {
        display: inline-block;
        min-width: 40px;
    }
</style>