<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">Добавление участника проекта</span>
      <a class="close" @click="$emit('close')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
              fill="#0033A0"/>
        </svg>
      </a>
    </v-card-title>
    <v-card-text>
      <v-container>
        <template v-for="(expert, expertIndex) in experts">
          <div class="selitem" :key="expertIndex+'_idxexpert'"
               v-if="!expertsToExclude.includes(expert.id)"
               @click="$emit('select', expert)">
            {{ expert.fullName }}
          </div>
        </template>
        <div v-if="experts.filter(e=>!expertsToExclude.includes(e.id)).length===0">Нет сотрудников для выбора</div>
      </v-container>
    </v-card-text>

  </v-card>
</template>

<script>

export default {
  name: 'AddApplication',
  props: ['experts', 'expertsToExclude'],
  data: () => ({}),
  methods: {},
  beforeMount() {}
}
</script>

<style lang="scss" scoped>
.v-dialog a {
  text-decoration: underline;
}

.selitem {
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
}

.selitem:hover {
  background: #cccccc;
  cursor: pointer;
}
</style>
