export const headers = [
{
    text: 'Статья',
    value: 'text',
},
{
    text: 'План',
    value: 'plan',
    width: 100
},
{
    text: 'Факт',
    value: 'fact',
    width: 100
},
{
    text: '',
    value: 'changeTax',
    width: 90
}]